import React from 'react'
import ReactWOW from 'react-wow'
import visionarStudioIMac from '../../assets/images/visionar-studio/VisionarStudio-iMac-Pro-27-SpaceGray1280.png'
import howitsWork from "../../assets/images/how-its-work.png";
import {Link} from "gatsby";

const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Visionar Studio</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>The creator for your AR experience</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link className="default-btn" to="pricing"><i className="flaticon-right"></i> Start Creating <span></span></Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={howitsWork} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner