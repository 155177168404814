import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import VisionarStudioOne from "../components/VisionarStudio/VisionarStudioOne";
import VisionarStudioTwo from "../components/VisionarStudio/VisionarStudioTwo";
import VisionarStudioThree from "../components/VisionarStudio/VisionarStudioThree";
import ProjectStartArea from "../components/Index/ProjectStartArea";
import VisionarStudioBanner from "../components/VisionarStudio/VisionarStudioBanner";

const VisionarStudio = () => {
    return (
        <Layout>
            <Navbar />
            <VisionarStudioBanner />
            <VisionarStudioOne />
            <VisionarStudioTwo />
            <VisionarStudioThree />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default VisionarStudio